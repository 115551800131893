<app-track-view
  *ngFor="let track of tracks$ | async"
  [track]="track"
></app-track-view>

<div #anchor></div>
<div *ngIf="hasMore$ | async" fxLayoutAlign="center center">
  <div fxFlex="nogrow">
    <mat-spinner></mat-spinner>
  </div>
</div>
