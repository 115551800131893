<div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
  <mat-card
    fxFlexFill
    class="card"
    fxLayoutAlign="start center"
    fxLayoutAlign.xs="center center"
    fxLayout="row"
  >
    <mat-card-header
      fxFlexOffset.gt-xs="3"
      fxFlex="30"
      fxFlex.xs="70"
      *ngIf="track$ | async as track"
    >
      <div
        mat-card-avatar
        [ngStyle]="{
          'background-image': 'url(' + track.album.images[0].url + ')',
          'background-size': 'cover'
        }"
      ></div>
      <mat-card-title>{{ track.name }}</mat-card-title>
      <mat-card-subtitle>{{ track.artists[0].name }}</mat-card-subtitle>
    </mat-card-header>
    <div
      fxFlexOffset.gt-xs="15"
      fxFlex="48"
      fxFlex.xs="25"
      fxLayoutAlign="center center"
      fxLayout="column"
    >
      <mat-card-actions
        fxFlexFill
        fxLayout="row"
        fxLayoutAlign="start center"
        *ngIf="track$ | async as track"
      >
        <button
          fxFlex="5"
          fxFlexOffset="32"
          fxHide.xs
          mat-icon-button
          (click)="shuffle()"
        >
          <mat-icon
            [ngClass]="{ green: (shuffle$ | async) }"
            class="icon"
            svgIcon="shuffle"
          ></mat-icon>
        </button>
        <button
          fxFlex="5"
          fxFlexOffset="5"
          fxHide.xs
          mat-icon-button
          (click)="previous()"
        >
          <mat-icon>skip_previous</mat-icon>
        </button>
        <button
          id="play-button"
          fxFlex="8"
          fxFlex.xs="30"
          fxFlexOffset="3"
          class="mobile-icon"
          mat-icon-button
          (click)="play()"
          *ngIf="paused$ | async"
        >
          <mat-icon id="play-icon">play_circle_outline</mat-icon>
        </button>
        <button
          id="play-button"
          fxFlex="8"
          fxFlex.xs="30"
          fxFlexOffset="3"
          class="mobile-icon"
          mat-icon-button
          (click)="pause()"
          *ngIf="(paused$ | async) === false"
        >
          <mat-icon id="play-icon">pause_circle_outline</mat-icon>
        </button>
        <button
          fxFlex="5"
          fxFlex.xs="30"
          fxFlexOffset="3"
          class="mobile-icon"
          mat-icon-button
          (click)="next()"
        >
          <mat-icon>skip_next</mat-icon>
        </button>
        <button
          fxFlex="5"
          fxFlexOffset="5"
          *ngIf="isLiked$ | async"
          mat-icon-button
          (click)="like(false)"
          matTooltip="Unlike"
        >
          <mat-icon>favorite</mat-icon>
        </button>
        <button
          fxFlex="5"
          fxFlexOffset="5"
          class="mobile-icon"
          *ngIf="(isLiked$ | async) === false"
          mat-icon-button
          (click)="like(true)"
          matTooltip="Like"
        >
          <mat-icon>favorite_border</mat-icon>
        </button>
      </mat-card-actions>
      <mat-card-content
        fxFlexFill
        fxFlex="90"
        fxHide.xs
        fxLayoutAlign="center center"
        fxLayout="row"
        *ngIf="track$ | async as track"
      >
        <p class="right">{{ value | secToMin }}</p>
        <mat-slider
          fxFlex
          color="accent"
          min="0"
          [max]="track.duration_ms / 1000"
          [(ngModel)]="value"
          (ngModelChange)="onChangeSlider()"
        >
        </mat-slider>
        <p>{{ track.duration_ms / 1000 | secToMin }}</p>
      </mat-card-content>
    </div>
  </mat-card>
</div>
