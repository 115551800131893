<div class="margin-top">
  <h4>{{ filter.label }}</h4>
  <div class="margin-top" fxLayout="row" fxLayoutAlign="space-between center">
    <mat-icon
      class="icon"
      fxFlex="10"
      [svgIcon]="filter.min"
      [matTooltip]="filter.min"
    ></mat-icon>
    <nz-slider
      fxFlex="65"
      (ngModelChange)="onChange()"
      nzRange
      [nzMin]="filter.extremeValues.min"
      [nzMax]="filter.extremeValues.max"
      [nzStep]="filter.step"
      [(ngModel)]="rangeValues"
    ></nz-slider>
    <mat-icon
      fxFlex="10"
      [svgIcon]="filter.max"
      [matTooltip]="filter.max"
    ></mat-icon>
  </div>
</div>
