<div
  *ngIf="!this.media.isActive('xs'); else mobile"
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <mat-card
    class="card"
    [ngClass]="{ playing: (isPlaying$ | async) }"
    fxFlex
    fxFlexAlign="center center"
  >
    <mat-card-header fxLayout="row" fxLayoutAlign="center center">
      <div
        mat-card-avatar
        [ngStyle]="{
          'background-image': 'url(' + track.album.images[0].url + ')',
          'background-size': 'cover'
        }"
      ></div>
      <div fxFlex="nogrow">
        <mat-card-title>{{ track.name }}</mat-card-title>
        <mat-card-subtitle>{{ track.artists[0].name }}</mat-card-subtitle>
      </div>
      <div fxFlex></div>
      <button
        *ngIf="isLiked$ | async"
        mat-icon-button
        (click)="unlike()"
        matTooltip="Unlike"
      >
        <mat-icon>favorite</mat-icon>
      </button>
      <button
        *ngIf="!(isLiked$ | async)"
        mat-icon-button
        (click)="like()"
        matTooltip="Like"
      >
        <mat-icon>favorite_border</mat-icon>
      </button>
      <button mat-icon-button (click)="play()" matTooltip="Play">
        <mat-icon>play_circle_outline</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="addoToPlayback()"
        matTooltip="Add to queue"
      >
        <mat-icon>playlist_add</mat-icon>
      </button>
      <button
        mat-icon-button
        (click)="remove()"
        matTooltip="Remove from tracklist"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-card-header>
  </mat-card>
</div>

<ng-template #mobile>
  <div
    fxLayout="row"
    fxLayoutAlign="start center"
    class="container"
    (click)="play()"
  >
    <div
      class="cover"
      [ngStyle]="{
        'background-image': 'url(' + track.album.images[0].url + ')',
        'background-size': 'cover'
      }"
    ></div>
    <div fxFlexOffset="5" fxFlex="nogrow">
      <p id="title" [ngClass]="{ active: (isPlaying$ | async) }">
        {{ track.name }}
      </p>
      <p id="subtitle">{{ track.artists[0].name }}</p>
    </div>
  </div>
</ng-template>
