<div fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div fxFlexFill fxLayoutAlign="center center">
    <div id="logo">
      <h1><a id="logo-text" class="white" href="">Tracklists</a></h1>
    </div>
    <div fxFlex class="text-centered text">
      <h1>Log in to Spotify</h1>
      <p class="bigger">You need to be connected to load your songs.</p>
      <div>
        <button
          mat-raised-button
          color="accent"
          (click)="loginToSpotify()"
          disabled="{{ loggingIn$ | async }}"
        >
          <span *ngIf="(loggingIn$ | async) === false">Login</span>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            *ngIf="loggingIn$ | async"
          >
            Logging in...
            <mat-spinner
              *ngIf="loggingIn$ | async"
              diameter="16"
              class="margin-left"
              color="accent"
            ></mat-spinner>
          </div>
        </button>
      </div>
    </div>
  </div>
</div>
