<div fxFlexFill fxLayout="column" fxLayoutAlign="start center">
  <div
    [fxFlex]="(playingTrack$ | async) && !this.media.isActive('xs') ? 85 : 100"
    *ngIf="(isSpinning$ | async) === false; else loadingTracks"
    fxFlexFill
  >
    <div
      fxFlexFill
      *ngIf="
        (arePlaylistLoaded$ | async) && (areTracksLoaded$ | async);
        else noTracks
      "
    >
      <div
        *ngIf="!this.media.isActive('xs'); else mobile"
        fxFlexFill
        fxLayout="row"
      >
        <!----------------- LEFT BAR ----------------->
        <div
          fxFlexOffset.lt-lg="5"
          fxFlexOffset="10"
          fxFlex.lt-lg="30"
          fxFlex="25"
          fxFlexFill
        >
          <div fxLayout="column" fxFlexFill>
            <div fxFlexOffset="10" fxFlex="90" class="left-bar">
              <mat-tab-group mat-align-tabs="center" fxFlexFill>
                <mat-tab label="Playlists">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="90">
                      <app-playlist-list></app-playlist-list>
                    </div>
                  </div>
                </mat-tab>
                <mat-tab label="Filters">
                  <div fxLayout="row" fxLayoutAlign="center center">
                    <div fxFlex="80">
                      <app-genre-list></app-genre-list>
                      <app-filter-list></app-filter-list>
                    </div>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
          </div>
        </div>
        <!----------------- RIGHT BAR ----------------->
        <div fxFlex.lt-lg="55" fxFlex="40" fxFlexOffset="5" fxFlexFill>
          <div fxLayout="column" fxFlexFill>
            <div
              fxFlexOffset="5"
              fxLayout="column"
              fxFlexFill
              fxFlex.lt-md="10"
              fxFlex="15"
            >
              <div fxFlex="nogrow" fxAlign="row">
                <h1>Tracklists</h1>
                <div fxFlex></div>
                <div fxFlex="nogrow">
                  <button
                    mat-icon-button
                    (click)="savePlaylist()"
                    matTooltip="Save as playlist"
                  >
                    <mat-icon>save</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="loadPlaylist()"
                    matTooltip="Reload Spotify tracks"
                  >
                    <mat-icon>refresh</mat-icon>
                  </button>
                  <button
                    mat-icon-button
                    (click)="signOut()"
                    matTooltip="Log out"
                  >
                    <mat-icon>power_settings_new</mat-icon>
                  </button>
                </div>
              </div>

              <mat-divider></mat-divider>
              <div fxFlex="nogrow" fxAlign="row" fxLayoutAlign="start center">
                <button
                  mat-raised-button
                  color="accent"
                  class="play"
                  (click)="playAll()"
                  [disabled]="(trackNumber$ | async) === 0"
                >
                  <mat-icon>play_circle_outline</mat-icon>
                  Play tracklist
                </button>
                <!--               <button
                mat-raised-button
                [disabled]="(trackNumber$ | async) === 0"
                (click)="addRecommended()"
              >
                Add recommendations
              </button> -->
                <div fxFlex></div>
                <p class="no-marign">{{ trackNumber$ | async }} tracks</p>
              </div>
            </div>
            <!----------------- TRACK LIST ----------------->
            <div
              class="track-list"
              fxFlexFill
              fxFlex
              *ngIf="(trackNumber$ | async) > 0; else noActiveTrack"
            >
              <app-track-list></app-track-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!----------------- PLAYER ----------------->
  <div
    fxFlexFill
    fxFlex="15"
    fxLayout="row"
    *ngIf="
      (arePlaylistLoaded$ | async) &&
      (areTracksLoaded$ | async) &&
      (playingTrack$ | async) &&
      (isSpinning$ | async) === false &&
      !this.media.isActive('xs')
    "
  >
    <div fxFlexOffset="10" fxFlex="70">
      <app-player [track$]="playingTrack$"></app-player>
    </div>
  </div>
</div>

<!----------------- NO TRACKS SAVED ----------------->
<ng-template #noTracks>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
    <div fxFlex="nogrow" class="text-centered text">
      <h1>Almost there</h1>
      <p class="bigger">
        We need to load your songs from Spotify, it may take a few minutes.
      </p>
      <button
        *ngIf="(isSpinning$ | async) === false"
        mat-raised-button
        color="accent"
        (click)="loadPlaylist()"
      >
        Load music
      </button>
    </div>
  </div>
</ng-template>

<!----------------- LOADING TRACKS ----------------->
<ng-template #loadingTracks>
  <div id="logo">
    <h1><a class="white" id="logo-text" href="">Tracklists</a></h1>
  </div>
  <div fxLayout="column" fxFlexFill fxLayoutAlign="center center">
    <h2 class="text-centered">Loading your tracks</h2>

    <div *ngIf="(this.userTopTrack$ | async) === false as track; else tracks">
      <mat-spinner color="accent" mode="indeterminate"></mat-spinner>
    </div>
    <ng-template #tracks>
      <p class="text-centered">Including</p>

      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-card fxLayoutAlign="start center" fxLayout="row">
          <mat-card-header
            fxFlexOffset="3"
            fxFlex
            *ngIf="this.userTopTrack$ | async as track"
            [@fadeIn]="fadeInState"
            [@fadeInOnEnter]
          >
            <div
              mat-card-avatar
              [ngStyle]="{
                'background-image': track
                  ? 'url(' + track.album.images[0].url + ')'
                  : url('src/assets/grey_square.png'),
                'background-size': 'cover'
              }"
            ></div>
            <mat-card-title>{{ track.name }}</mat-card-title>
            <mat-card-subtitle>{{ track.artists[0].name }}</mat-card-subtitle>
          </mat-card-header>
        </mat-card>
      </div>
      <div id="progress-bar">
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </div>
    </ng-template>
  </div>
</ng-template>

<!----------------- NO ACTIVE TRACK----------------->
<ng-template #noActiveTrack>
  <div fxFlexFill fxLayout="column" fxLayoutAlign="start center">
    <div fxFlex="15" fxFlex.xs="30"></div>
    <h3 fxFlex="nogrow" class="text-centered">No playlist selected</h3>
    <p fxFlex="nogrow" class="text-centered">
      Select a playlist to load its tracks
    </p>
    <mat-icon
      *ngIf="!this.media.isActive('xs')"
      fxFlexOffset="10"
      fxFlex="nogrow"
      class="icon"
      svgIcon="curvy-arrow"
    ></mat-icon>
    <mat-icon
      *ngIf="this.media.isActive('xs')"
      fxFlexOffset="15"
      fxFlex="nogrow"
      class="rotate"
      svgIcon="straight-arrow"
    ></mat-icon>
  </div>
</ng-template>

<!----------------- MOBILE VERSION ----------------->
<ng-template #mobile>
  <div fxFlexFill fxLayout="column" class="container">
    <div fxFlexOffset="5" fxFlex="5" class="text-centered margin-bottom-4">
      <div
        fxFlexFill
        fxLayout="row"
        class="padding-left"
        fxLayoutAlign="start center"
      >
        <h1 class="padding-top-4">Tracklists</h1>
        <div fxFlex></div>
        <div fxFlex="nogrow">
          <button
            mat-icon-button
            (click)="savePlaylist()"
            matTooltip="Save as playlist"
          >
            <mat-icon>save</mat-icon>
          </button>
          <button
            mat-icon-button
            (click)="loadPlaylist()"
            matTooltip="Reload Spotify tracks"
          >
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-icon-button (click)="signOut()" matTooltip="Log out">
            <mat-icon>power_settings_new</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <!--  TODO: Create a header component -->
    <mat-divider></mat-divider>
    <div fxFlex="5" fxAlign="row" fxLayoutAlign="start center" class="margins">
      <button
        mat-raised-button
        color="accent"
        class="play"
        (click)="playAll()"
        [disabled]="(trackNumber$ | async) === 0"
      >
        <mat-icon>play_circle_outline</mat-icon>
        Play tracklist
      </button>
      <div fxFlex></div>
      <p class="no-marign">{{ trackNumber$ | async }} tracks</p>
    </div>

    <div fxFlex="85">
      <mat-tab-group
        fxFlexFill
        fxFill
        mat-align-tabs="center"
        headerPosition="below"
      >
        <mat-tab #tracks>
          <ng-template mat-tab-label>
            <div
              fxLayout="column"
              class="margin-top"
              fxLayoutAlign="center center"
            >
              <mat-icon
                svgIcon="tracklists-fill"
                *ngIf="tracks?.isActive"
              ></mat-icon>
              <mat-icon
                svgIcon="tracklists-empty"
                *ngIf="!tracks?.isActive"
              ></mat-icon>

              <div class="nav-titles">Tracks</div>
            </div>
          </ng-template>
          <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
            <div
              class="track-list"
              fxFlexFill
              *ngIf="(trackNumber$ | async) > 0; else noActiveTrack"
              fxFlex="90"
              fxFlexOffset="10"
            >
              <app-track-list></app-track-list>
            </div>
          </div>
        </mat-tab>
        <mat-tab #playlists>
          <ng-template mat-tab-label>
            <div
              fxLayout="column"
              class="margin-top"
              fxLayoutAlign="center center"
            >
              <mat-icon
                svgIcon="playlists-fill"
                *ngIf="playlists?.isActive"
              ></mat-icon>
              <mat-icon
                svgIcon="playlists-empty"
                *ngIf="!playlists?.isActive"
              ></mat-icon>

              <div class="nav-titles">Playlists</div>
            </div>
          </ng-template>
          <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="80" class="playlists">
              <app-playlist-list></app-playlist-list>
            </div>
          </div>
        </mat-tab>
        <mat-tab #filters>
          <ng-template mat-tab-label>
            <div
              fxLayout="column"
              class="margin-top"
              fxLayoutAlign="center center"
            >
              <mat-icon
                svgIcon="filters-fill"
                *ngIf="filters?.isActive"
              ></mat-icon>
              <mat-icon
                svgIcon="filters-empty"
                *ngIf="!filters?.isActive"
              ></mat-icon>
              <div class="nav-titles">Filters</div>
            </div>
          </ng-template>
          <div fxLayout="row" fxLayoutAlign="center center">
            <div fxFlex="80">
              <app-genre-list></app-genre-list>
              <app-filter-list></app-filter-list>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <!----------------- PLAYER ----------------->
    <div
      fxFlexFill
      fxFlex="15"
      fxLayout="row"
      *ngIf="
        (arePlaylistLoaded$ | async) &&
        (areTracksLoaded$ | async) &&
        (playingTrack$ | async) &&
        (isSpinning$ | async) === false &&
        tracks?.isActive
      "
      class="player"
    >
      <div fxFlexOffset="5" fxFlex="90">
        <app-player [track$]="playingTrack$"></app-player>
      </div>
    </div>
  </div>
</ng-template>
