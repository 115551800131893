<h2 mat-dialog-title>Save tracks in a playlist</h2>
<div mat-dialog-content>
  <mat-form-field appearance="legacy" color="accent">
    <mat-label>Playlist name</mat-label>
    <input matInput [(ngModel)]="name" />
  </mat-form-field>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-button [mat-dialog-close]="name">Save</button>
</div>
