<section
  class="overflow-hidden"
  fxFlexFill
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div id="logo">
    <h1>
      <a class="white" id="logo-text" href="https://tracklists.io"
        >Tracklists</a
      >
    </h1>
  </div>
  <div
    class="spacer layer1"
    [@heartBeat]="animStates[1]"
    (@heartBeat.done)="animDone(1)"
  ></div>
  <div
    class="spacer layer2"
    [@heartBeat]="animStates[1]"
    (@heartBeat.done)="animDone(1)"
  ></div>
  <div class="text-centered">
    <h1>Dig your tracks</h1>
    <p class="bigger">using Spotify's hidden parameters</p>
    <button
      mat-raised-button
      color="accent"
      (click)="navigateToLogin()"
      (mouseenter)="toggleAnimation(1)"
      (mouseleave)="toggleAnimation(1)"
    >
      <mat-icon class="icon" svgIcon="shovel"></mat-icon><span>Dig it!</span>
    </button>
  </div>
</section>
<section fxFlexFill fxLayout="column" fxLayoutAlign="start end">
  <div
    class="spacer layer3"
    [@heartBeat]="animStates[1]"
    (@heartBeat.done)="animDone(1)"
  ></div>
  <div class="margin-top">
    <img
      alt="Load your Spotify library"
      src="../../assets/landing/download.svg"
    />
    <h1>Load your library</h1>
    <p class="bigger">based on your playlists and liked tracks</p>
    <button
      mat-raised-button
      color="primary"
      (click)="navigateToLogin()"
      (mouseenter)="toggleAnimation(2)"
      (mouseleave)="toggleAnimation(2)"
    >
      Let's go!
    </button>
  </div>
</section>
<section id="three" fxFlexFill fxLayout="column" fxLayoutAlign="center start">
  <div
    class="spacer layer4"
    [@rotate]="animStates[2]"
    (@rotate.done)="animDone(2)"
  ></div>
  <div>
    <img
      alt="Filter your Spotify library"
      src="../../assets/landing/filters.svg"
    />
    <h1>Play with parameters</h1>
    <p class="bigger">Like danceability, chill or BPM</p>
    <button
      mat-raised-button
      color="primary"
      (click)="navigateToLogin()"
      (mouseenter)="toggleAnimation(2)"
      (mouseleave)="toggleAnimation(2)"
    >
      Let's play!
    </button>
  </div>
</section>
<section fxFlexFill fxLayout="column" fxLayoutAlign="center end">
  <div>
    <img
      alt="(Re)discover your Spotify library"
      src="../../assets/landing/funk.svg"
    />
    <h1>Discover all the genres</h1>
    <p class="bigger">behind your tracks</p>
    <button mat-raised-button color="primary" (click)="navigateToLogin()">
      Go!
    </button>
  </div>
  <div
    class="spacer layer5"
    [@pulse]="animStates[3]"
    (@pulse.done)="loopDone()"
  ></div>
</section>
<section
  class="overflow-hidden"
  fxFlexFill
  fxLayout="row"
  fxLayoutAlign="center center"
>
  <div
    class="spacer layer6"
    [@pulse]="animStates[3]"
    (@pulse.done)="loopDone()"
  ></div>
  <div
    class="spacer layer7"
    [@pulse]="animStates[3]"
    (@pulse.done)="loopDone()"
  ></div>
  <div class="text-centered">
    <h1>Free and open source</h1>
    <br />
    <h1>
      <a
        class="red purple"
        target="_blank"
        rel="noreferrer"
        href="https://github.com/Jackseed/tracklists"
        >❤</a
      >
    </h1>
  </div>
</section>
